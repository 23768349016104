/* --------------------------------------------------------------

 Hartija Css Print  Framework
   * Version:   1.0

-------------------------------------------------------------- */
@charset "utf-8";
.google-maps img {
  max-width: none !important;
}
body {
width:100% !important;
margin:0 !important;
padding:0 !important;
line-height: 1.45;
font-family: Garamond,"Times New Roman", serif;
color: #000;
background: none;
font-size: 14pt; }

/* Headings */
h1,h2,h3,h4,h5,h6 { page-break-after:avoid; }
h1{font-size:19pt;}
h2{font-size:17pt;}
h3{font-size:15pt;}
h4,h5,h6{font-size:14pt;}


p, h2, h3 { orphans: 3; widows: 3; }

code { font: 12pt Courier, monospace; }
blockquote { margin: 1.2em; padding: 1em;  font-size: 12pt; }
hr { background-color: #ccc; }

/* Images */
img { float: left; margin: 1em 1.5em 1.5em 0; max-width: 100% !important; }
a img { border: none; }

/* Links */
a:link, a:visited { background: transparent; font-weight: 700; text-decoration: underline;color:#333; }
a:link[href^="http://"]:after, a[href^="http://"]:visited:after { content: " (" attr(href) ") "; font-size: 90%; }

abbr[title]:after { content: " (" attr(title) ")"; }

/* Don't show linked images  */
a[href^="http://"] {color:#000; }
a[href$=".jpg"]:after, a[href$=".jpeg"]:after, a[href$=".gif"]:after, a[href$=".png"]:after { content: " (" attr(href) ") "; display:none; }

/* Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate */
a[href^="#"]:after, a[href^="javascript:"]:after {content: "";}

/* Table */
table { margin: 1px; text-align:left; }
th { border-bottom: 1px solid #333;  font-weight: bold; }
td { border-bottom: 1px solid #333; }
th,td { padding: 4px 10px 4px 0; }
tfoot { font-style: italic; }
caption { background: #fff; margin-bottom:2em; text-align:left; }
thead {display: table-header-group;}
img,tr {page-break-inside: avoid;}

/* Hide various parts from the site
#header, #footer, #navigation, #rightSideBar, #leftSideBar
{display:none;}
*/

/* **************************
 　　共通
************************** */
html,body {
	width:100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: #464646;
	font-size:15px;
	line-height: 1.8em;

}
a {text-decoration: none;color:#444444;}
a:hover { color:#666666; text-decoration: none;}
a:hover img { filter:alpha(opacity=50); -moz-opacity:0.50; opacity:0.50;text-decoration: none;}

.clear {clear: both;}

.txt01{font-size:30px;font-weight: bold;}
.txt02{font-weight: bold;}
.txt03{font-size:33px;font-weight: bold;margin-bottom:15px;}
.txt-right{text-align:right;}
.txt-bold{font-weight: bold;}

#wrapper{width:100%;}


/* **************************
 　　ヘッダー
************************** */

#wrap01{
	width:100%;
	height:120px;
	margin:0 auto;
	background:#fff;
	z-index:10;
}

header{position:relative;}

h1 img{
	width:178px;
	position: absolute;
	left:16px;
	top:0;
}
nav{
	position: absolute;
	right:14px;
	top:54px;
}
nav li{
	display:inline-block;
	padding:4px 34px;
	border-left:3px solid #ee7800;
}
nav li:last-child{border-right:3px solid #ee7800;}
nav li a{
	color:#ee7800;
	font-size:20px;
	font-weight: bold;
}
nav li a:hover{color:#f8c799;}
#nav_toggle{display: none;}
.navi1 a{color:#ee7800;text-decoration: none;}
.navi2 a{color:#ee7800;text-decoration: none;}
.navi3 a{color:#ee7800;text-decoration: none;}
.navi1 a:hover{color:#f8c799;}
.navi2 a:hover{color:#f8c799;}
.navi3 a:hover{color:#f8c799;}
.navi4{display: none;}
.navi5{display: none;}
.navi6{display: none;}
.logo{display: none;}


/* **************************
 　　メインイメージ
************************** */

#main01-p{
	padding:0;
	width: 100%;
	background: url(../img/image01-3.jpg) center center / cover no-repeat;
	display:table;
}
#main01-s{display: none;}
#main01{display: none;}
.box{
	display:table-cell;
	vertical-align:middle;
}
.m-logo{
	width:458px;
	margin:0 auto;
}

.m-text{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	padding:25px 0 0 0;
	color:#fff;
	font-size:17px;
	text-align:center;
	line-height: 1.8em;
	letter-spacing: 0.2em;
}

/* **************************
 　　ニュース
************************** */

#news-b{position: relative;}
#news{
	position:absolute;top:-120px;
	width: 100%;
	height: 120px;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	text-align:center;
}
#news h2{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	color:#fff;
	font-size:25px;
	font-weight: normal;
	text-align:left;
	line-height: 130%;
	display: inline-block;
	margin:30px auto;
	padding:0 30px;
	border-left:8px solid #fff;
	border-right:8px solid #fff;
}
#news-s{display:none;}


/* **************************
 　　ご挨拶
************************** */

.greet{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	width:830px;
	margin:70px auto;
}
.greet h3{
	margin:0 0 20px 0;
	padding:0 0 20px 0;
	font-size:32px;
	line-height: 1.5em;
	font-weight: bold;
	text-align:center;
	border-bottom:1px solid #000;
}
.greet p{
	font-size:19px;
	line-height: 1.8em;
}


/* **************************
 　　内覧会のご案内
************************** */

#guide-h{
	width:100%;
	background:#f1ede2;
	padding:70px 0;
}
#guide{
	width:1030px;
	margin:0 auto;
	padding: 0;
}
.tb{display:none;}
.sp{display:none;}
#guide h4{
	padding-bottom: 30px;
	font-size:30px;
	line-height: 1.5em;
	color:#ee7800;
	border-bottom:1px solid #fff;
	text-align:center;
}
#guide h4:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:5px;
	position: relative;
	top: -5px;
	left:0;
}
.g-group07{width:600px;float:left;}
#g01{
	padding:20px;
	font-size:15px;
	line-height: 1.8em;
	padding-bottom:10px;

}
#g02{
	height:260px;
	padding-bottom:4px;
	position: relative;
}
#g02 .icon02{
	display:block;
	width:112px;
	height:121px;
	position: absolute;
	top:0;
	left:0;
	z-index:1;
}
#g02 .icon02 img{width:100%;}
#g02 .con01{
	display:inline-block;
	width:425px;
	height:180px;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	position: absolute;
	top:47px;
	left:75px;
	padding:18px 18px 18px 50px;
	font-size:15px;
	line-height: 1.8em;
}
.con01 h5{
	font-size:17px;
	color:#ee7800;
	border-bottom:1px solid #ee7800;
	width:100%;
	padding-bottom:7px;
	margin-bottom:10px;
}
.con01 table{
	width:400px;

}
.con01 th{width:120px;}
.con01 th,td{
	padding:5px 5px;
	text-align:left;
	word-break : break-all;
	font-size:15px;
	line-height: 1.8em;

}
#g03{
	height:220px;
	position: relative;
}
#g03 .icon02{
	display:inline-block;
	width:113px;
	height:120px;
	position: absolute;
	top:0;
	left:0;
	z-index:1;
}
#g03 .icon02 img{width:100%;}
#g03 .con01{
	display:inline-block;
	width:425px;
	height:120px;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	position: absolute;
	top:47px;
	left:75px;
	padding:18px 18px 18px 50px;
	font-size:15px;
	line-height: 1.8em;
}

#g04{margin:0;padding:0;}
#g04 img{width:430px;height:322px;}
#g05{
	position: absolute;
	top:340px;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	padding:36px 15px 36px 15px;
	text-align:center;
	color:#fff;
	width:400px;
	line-height: 150%;
	font-size:18px;
	margin-top:20px;
}
#g05 p{
	padding-top:0;
	margin-bottom:16px;
	border-bottom:1px solid #fff;
	padding-bottom: 10px;
}

.g-group08{width:430px;float:left;position: relative;}
.g-group03{display: none;}
.g-group04{display: none;}
.g-group05{display: none;}
.g-group06{display: none;}
.g-group01{display:none;}
.g-group02{display: none;}
.flex{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.map h5{
	font-size:20px;
	font-weight: bold;
	padding-bottom:12px;
}

.center{text-align:center;}


/* **************************
 　　施設概要
************************** */

.facility {padding:70px 0 0 0;}
.facility h5{font-size:20px;padding:0 0 10px 0;}
.summary{
	width:830px;
	margin:20px auto 20px auto;
	padding:35px 0 20px 0;
	border-top:1px solid #999999;
}
.summary tr{border-bottom:1px solid #999999;}
.summary th{
	padding:28px 30px;
	font-weight: normal;
	font-size:15px;
	line-height: 1.8em;
	text-align:left;
}
.summary td{
	padding:28px 30px;
	font-weight: normal;
	font-size:15px;
	line-height: 1.8em;
	text-align:left;
}
.map {
	width:830px;
	height:560px;
	margin:55px auto;
}
.map iframe{height:560px;margin-bottom:20px;}


/* **************************
 　　法人概要
************************** */

#corpo{
	padding:70px 0;
}
#corpo h3{
	padding: 18px 0 10px 0;
	font-size:30px;
	line-height: 1.5em;
	font-weight: bold;
	color:#ee7800;
	text-align:center;
}
#corpo h3:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:10px;
	position: relative;
	top: -5px;
	left:0;
}


/* **************************
 　　お問い合わせ
************************** */

#contact{
	width:100%;
	background:#f1ede2;
	text-align:center;
	margin:0;
	padding:70px 0 70px 0;
}
#contact h3{
	margin:0 0 20px 0;
	padding: 18px 0 18px 0;
	font-size:30px;
	line-height: 1.5em;
	color:#ee7800;
	font-weight: bold;
	text-align:center;
	border-bottom:1px solid #fff;
}
#contact h3:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:10px;
	position: relative;
	top: -5px;
	left:0;
}
#contact p{
	margin:0;
	padding:0;
	line-height: 1.8em;
}
#contact .btn01{
	display: block;
	margin:50px 0 20px 0;
}
#contact .btn01 a{
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	padding:16px 30px;
	font-size:21px;
	line-height: 1.8em;
	font-weight: bold;
	color:#fff;
}
#contact .btn01 a:hover{
	background: #ffc578;
	background: -moz-linear-gradient(top, #ffc578 0%, #fb9d23 100%);
	background: -webkit-linear-gradient(top, #ffc578 0%,#fb9d23 100%);
	background: linear-gradient(to bottom, #ffc578 0%,#fb9d23 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0 );
}
#footer{
	display: block;
	width:100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	color:#fff;
	padding:20px 0;
	text-align:center;
}


/* **************************
 　　フッター
************************** */

#footer ul{display: inline-block;margin:20px 0;}
#footer li{
	display: table-cell;
	vertical-align: middle;
	list-style-type: none;
	margin:0;
	padding:0 40px;
	border-right:1px solid #fff;
	font-size:14px;
}
#footer li:last-child{border:none;}
#footer img{width:180px;}
#footer .copy{font-size:12px;}

/* **************************
 　　ページトップ
************************** */

#page-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index:20;
}
#page-top a {
	text-align: center;
	display: block;
}
#page-top a:hover img{ opacity: 0.6;}

#page-top img{width:90px;}

#page-top1 a {display: block;}
#page-top1 a:hover img{ opacity: 0.4;}

/* **************************
 　　SP固定ナビ
************************** */

#sp-fix{display:none;}

/* **************************
 　　js周り
************************** */


/* ------------- スクロールフェードインDown用 ------------- */
.fadeInDown {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:1s;
	-ms-animation-duration:1s;
	animation-duration:1s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown {
	0% { opacity: 1; -webkit-transform: translateY(0); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown {
	0% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(20px); transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}

.fadeInDown1 {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:3s;
	-ms-animation-duration:3s;
	animation-duration:3s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown1 {
	0% { opacity: 1; -webkit-transform: translateY(0); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown1 {
	0% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(30px); transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}
/* ------------- スクロールフェードインDown用 END ------------- */



// -------------
// 201812追加
// -------------

/* **************************
    変数
************************** */
// color
$main_color: #ee7800;
$second_color: #f29d4c;
$thrid_color: #f7bd8c;
$text_color: #464646;

$trans_speed_quick: .1s;
$trans_speed_normal: .3s;
$trans_speed_slow: 3s;

.spShow{
  display: none;
}
.pcShow{
  display: block;
}
.showOverTablet{
  display: block;
}
.showUnderTablet{
  display: none;
}
@media screen and (max-width: 1051px){
  .showOverTablet{
    display: none;
  }
  .showUnderTablet{
    display: block;
  }
}

@media screen and (max-width: 768px){
  .spShow{
    display: block;
  }
  .pcShow{
    display: none;
  }
}
.col{
  display: flex;
  &-2{
    .col-item{
      width: 50%;
    }
  }
}

.bg-veju{
  background: #f2ede2;
}
// googlefont teko
.font-teko{font-family: 'Teko', sans-serif;}

.btn02,
.btn03{
  box-sizing: border-box;
	border-radius: 8px;
	border: 1px solid $main_color;
	display: inline-block;
  vertical-align: middle;
	background: #fff !important;
	color: $main_color;
	font-weight: bold;
	position: relative;
	padding: 10px 15px;
	padding-right: calc(15px + 1em);
}
.btn02{
  &.spShow{
    display: none;
  }
}
.btn03{
	padding: 12px;
}
@media screen and (max-width: 768px){
  .btn02{
    margin: 25px auto 50px;
    display: block;
    text-align: center;
    max-width: 345px;
    width: 92%;
    padding: 10px 17px 10px 0;
    &.spShow{
      display: block;
    }
  }
  .btn03{
    .icon{
    	&-arrow{
    		&::before{
    		}
    		&.arrow-type-down{
    			&::before{
    				transform: translateY(-20%) rotate(135deg);
    			}
    		}
    	}
    }
  }
}
@media screen and (min-width: 769px){
  .hover-01,
  .hover-02{
  	position: relative;
  	transition-duration: $trans_speed_normal;
  	&:hover{
  		background: $second_color;
  		border-color: $second_color;
  		color: #fff;
  		.icon-arrow{
  			&::before{
  				transform: translate(50%, -50%) rotate(45deg);
  				border-color: #fff;
  			}
  		}
  	}
  }
  .hover-02{
  	&:hover{
  		.icon-arrow{
  			&::before{
  				transform: translate(0, 0) rotate(135deg);
  			}
  		}
  	}
  }
}
.icon{
	position: relative;
	&-arrow{
		&::before{
			content: '';
			display: inline-block;
			position: absolute;
			top: 55%;
			right: -1em;
			width: 7px;
			height: 7px;
			border-top: 3px solid $main_color;
			border-right: 3px solid $main_color;
			transform: translateY(-50%) rotate(45deg);
			transform-origin: top;
			transition-duration: $trans_speed_normal;
		}
		&.arrow-type-down{
			&::before{
				transform: translateY(-50%) rotate(135deg);
				transform-origin: center;
				position: static;
				margin-right: 15px;
			}
		}
	}
}


// ********************************
// top-page
// ********************************
.link{
  color: #fff;
  background: linear-gradient(to top,#b94620,#f19200);
  padding: 70px 10px;
  box-sizing: border-box;
  &-heading{
    font-size: 36px;
    font-weight: bold;
    line-height: 1.48;
    margin-bottom: 15px;
    letter-spacing: .1em;
  }
  &-text{
    font-size: 15px;
    line-height: 1.8;
    margin-bottom: 28px;
  }
  &-image{
    background-image: url(/img/office.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
  @media screen and (max-width: 1050px){
    padding: 5% 3%;
    &-heading{
      font-size: 3.5vw;
    }
    &-text{}
    &-image{}
  }
  @media screen and (max-width: 768px){
    padding: 0;
    &.col-item{
      width: 100%;
      background: none;
      color: #464646;
    }
    &-heading{
      box-sizing: border-box;
      width: 100vw;
      height: 53.33vw;
      padding: 8vw;
      font-size: 7vw;
      line-height: 1.5;
      background: url(/img/bg_krousar.jpg)no-repeat;
      background-size: cover;
      margin-bottom: 19px;
    }
    &-text{
      font-size: 15px;
      width: 92%;
      margin: 0 auto 29px;
    }
    &-image{
      display: none;
    }
  }
}
.guide{
  .link{
    padding-bottom: 90px;
    &-image{
      background-image: url(/img/img_guide.jpg);
      background-position: center center;
    }
  }
  @media screen and (max-width: 1050px){
    .link{
      padding-bottom: 5%;
    }
  }
  @media screen and (max-width: 768px){
    .link-heading{
      background: url(/img/bg_guide.jpg)no-repeat;
      background-size: cover;
    }
  }
}
/* **************************
    コンセプト
************************** */
.concept{
  width: 80%;
	max-width: 900px;
	margin: 90px auto;
	position: relative;
	&::before,
	&::after{
		content: '';
		height: 100%;
		width: 8px;
		background-color: $main_color;
		position: absolute;
		bottom: 0;
		left: -40px;
	}
	&::after{
		left: auto;
		right: -40px;
	}
	&-text{
		color: $text_color;
		font-size: 29.3px;
    font-size: 2.4vw;
		line-height: 1.78;
    white-space: normal;
		em{
			font-weight: bold;
			font-style: normal;
		}
	}
	.btn02{
		margin-top: 28px;
	}
  @media screen and (max-width: 768px){
    margin: 45px auto 0;
    margin-bottom: 0;
    width: 92%;
    &::before{
      left: 0;
    }
    &::after{
      right: 0;
    }
    &-text{
      font-size: 3.73vw;
      text-align: center;
    }
    .btn02{
      display: none;
    }
  }
}

// ********************************
// overlay
// ********************************
@media screen and (min-width: 1051px){
  .navi7{
    &::after{
      content: none;
    }
  }
}
@media screen and (max-width: 1050px){
  #overlay{
    .navi7{
      display: none;
    }
  }
  .line{
    display: flex;
    justify-content: space-around;
    li{
      float: none;
      width: 50%;
      &+li{
        border-left: 1px solid $main_color;
      }
    }
  }
}
@media screen and (max-width: 1050px){
  .navi4 ul{width:100%;}
  .navi4{
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    text-align: center;
  }
  .navi6{
  	width:100%;
  	text-align:center;
    border-bottom: 1px solid #fff;
    img{
      width: 16.6%;
    }
    span{
      margin-top: 5px;
      display: block;
    }
  }
  #overlay ul li.navi6 a{
    margin: 25px auto;
    padding: 0;
    border-right: 1px solid #fff;
  }
  #overlay ul li.navi6 li:nth-child(2) a{
    border-right: 0;
  }
  .navi6 a{
  	display:block;
  	padding:0 0 5% 0;
  }
}
@media screen and (max-width: 768px){
  /* **************************
   　　オーバーレイナビ
  ************************** */

  #overlay{
  	display: none;
  	width: 100%;
  	height:100%;
  	text-align: left;
  	position: fixed;
  	top: 0;
  	left:0;
  	z-index: 9999;
  	background: #ec7300;
  	margin:0;
  	padding: 0 8%;
  	box-sizing: border-box;
  }
  #overlay ul{
  	margin-top:40px;
  	padding-left: 0px;
  }
  #overlay ul li{
  	margin:0;
  	padding:0;
  	list-style-type: none;
  }
  #overlay ul li a{
  	display:block;
  	 padding: 25px 0;
  	color: #fff;
  	text-decoration: none;
  	font-size: 15px;
  	line-height: 1em;
  }
  #overlay ul li a:hover{color:rgba(255,255,255,0.6);}
  #overlay .logo{padding:30px 0 0 0;text-align:center;}
  #overlay .logo img{width:30%;}
  #overlay .header-title{
  	display: block;
  	color: #fff;
  	font-size: 14px;
  	text-align: center;
  }

  .navi1{
  	border-top:2px solid rgba(255,255,255,0.6);
  	border-bottom:2px solid rgba(255,255,255,0.6);
  }
  .navi2,
  .navi3{
  	border-bottom:2px solid rgba(255,255,255,0.6);
  }
  // .navi3{display: none;}
  // .navi4{display: none;}
  // .navi5{display: none;}
  .navi5 ul{width:100%;}
  .navi5 li{
  	margin:0;
  	padding:0;
  	display:inline-block;
  	width:50%;
  	box-sizing: border-box;
  	text-align:center;
  	font-size: 80%;
  }
  .navi5 li:first-child{	border-right:2px solid rgba(255,255,255,0.6);}
  .navi5 li a{margin:0;padding:0;}
  .navi5 li img{width:40px;padding:0 0 8px 0;}
  #overlay .line{
    margin-top: 0;
  }
  .line{
    li{vertical-align:top;box-sizing: border-box;float: left;}
  }
}
.summary{
  th{
    font-weight: bold;
  }
}

// ********************************
// 下層ページ
// ********************************
.under{
  ol,ul,li{
    list-style: none;
  }
  .inner{
    width: 1010px;
    margin: 0 auto;
    max-width: 100%;
    @media screen and (max-width: 1050px){
      width: 80%;
    }
  }
  img{
    max-width: 100%;
    vertical-align: bottom;
  }
  #wrap01{
    animation: none;
  }
  .greet{
    max-width: 1045px;
    width: 100%;
    p{
      width: 860px;
      margin: 0 auto;
    }
    @media screen and (max-width: 1050px){
      width: 92%;
      h3{
        margin: 0;
      }
      p{
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px){
    .menu-trigger{
      top: 8px;
    }
  }
}
.header{
  &-left{
    a{
      display: block;
      height: 130px;
      position: relative;
    }
  }
  &-logo{
    width: 198.5px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-title{
    font-size: 12.5px;
    font-weight: lighter;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
  &-right{
    display: none;
  }
}
@media screen and (max-width: 1050px){
  .header-title{
    display: none;
  }
}
.heading{
  height: 300px;
  padding-top: 130px;
  margin-top: -130px;
  background: url(/img/heading.jpg)no-repeat;
  background-size: cover;
  background-position: top 130px right;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 1050px){
    background-position: top right;
  }
  &-title{
    color: #fff;
    font-size: 36px;
    line-height: 1.48;
    letter-spacing: .1em;
    margin-left: 1em;
    position: relative;
    z-index: 1;
    &::before{
      content: none;
      content: '';
      position: absolute;
      width: 600px;
      height: 600px;
      top: 50%;
      border-radius: 50%;
      background: $main_color;
      transform: translate(-50%,-50%);
      z-index: -1;
    }
  }
  &-icon{
    font-size: 30px;
    line-height: 1.5em;
    font-weight: bold;
    color: #ee7800;
    text-align: center;
    &::before{
      content: " ";
      display: inline-block;
      width: 43px;
      height: 52px;
      background: url(/img/icon01.png);
      background-size: contain;
      vertical-align: middle;
      margin-right: 10px;
      position: relative;
      top: -5px;
      left: 0;
    }
  }
  @media screen and (max-width: 1050px){
    padding-top: 80px;
  }
  @media screen and (max-width: 768px){
    display: block;
    box-sizing: border-box;
    width: 100vw;
    height: 53.33vw;
    padding: 8vw;
    margin-top: 60px;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-size: cover;
    &-title{
      font-size: 7vw;
      margin-left: 0;
      &::before{
        content: none;
      }
    }
    &-icon{
      width: 92%;
      margin: 55px auto 0;
      padding-bottom: 14px;
      font-size: 24px;
      &::before{
        display: block;
        margin: 0 auto;
        width: 21px;
        height: 25.5px;
        background-repeat: no-repeat;
      }
    }
  }
}


//
// 就労継続支援B型事業所「クルーサ」
//
.krousar{
  .heading{
    background:
    url(/img/img_appearance.png),
    linear-gradient(to top, #d3edfb, #7ac7e9);
    background-repeat: no-repeat;
    background-size: 58%, 100%;
    background-position: right -127px bottom, center;
  }
  #contact{
    background: #fff;
  }
  @media screen and (max-width: 768px){
    .heading{
      background-image: url(/img/bg_krousar.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &-title{
        color: #464646;
      }
    }
    .inner{
      width: 100%;
    }
    .col{
      display: block;
      &-item{
        width: 100%;
      }
    }
  }
}
.about-krousar{
  margin: 70px auto 65px;
  display: flex;
  justify-content: space-between;
  &-logo{
    box-sizing: border-box;
    width: 21.5%;
  }
  &-text{
    width: 72%;
    font-size: 15px;
    line-height: 1.8;
  }
  @media screen and (max-width: 768px){
    display: block;
    margin:  45px auto 50px;
    &-logo{
      width: 47.8%;
      margin: 0 auto 25px;
    }
    &-text{
      width: 92%;
      margin: 0 auto;
    }
  }
}
.anchor{
  display: flex;
  justify-content: space-between;
  &-item{
    width: 47.6%;
    text-align: center;
  }
  .btn03{
    width: 100%;
  }
  @media screen and (max-width: 768px){
    &-item{
      width: 50%;
    }
    .btn03{
      border-radius: 0;
      border-left: 0;
      &+.btn03{
        border-right: 0;
      }
    }
  }
}
#work{
  h3{
    border-bottom: 1px solid $main_color;
  }
}
.work{
  &-text{
    max-width: 865px;
    margin: 24px auto 64px;
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 42px;
    &-item{
      box-sizing: border-box;
      width: calc((100% - 70px) / 3);
      border: 1px solid $main_color;
      border-radius: 12px;
      font-size: 15px;
      line-height: 1.8;
      padding: 20px 20px 14px;
      margin-bottom: 50px;
      &:last-child{
        width: 100%;
        padding: 20px;
        .work-list-heading,
        .work-list-text{
          display: inline-block;
        }
        .work-list-heading{
          margin: 0 40px 0 15px;
        }
        .work-list-text{}
      }
    }
    &-heading{
      font-weight: bold;
      text-align: center;
      color: $main_color;
      line-height: 1;
      margin-bottom: 20px;
    }
    &-image{
      max-width: 260px;
      width: 100%;
      margin-bottom: 6px;
    }
  }
  @media screen and (max-width: 768px){
    &-text{
      width: 92%;
      font-size: 15px;
      margin: 20px auto 45px;
      &:nth-of-type(1){
        margin: 15px auto 45px;
      }
    }
    &-list{
      display: block;
      width: 80%;
      margin: 20px auto 0;
      &-item{
        width: 100%;
        margin-bottom: 25px;
        &:last-child{
          margin-bottom: 50px;
          .work-list-heading{
            margin: 0 auto 14px;
            display: block;
          }
          .work-list-text{}
        }
      }
      &-heading{
      }
      &-image{
      }
      &-text{
        font-size: 14px;
      }
    }
  }
}
.side{
  &-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f29d4c;
    color: #fff;
    height: 260px;
    text-align: center;
    letter-spacing: .2em;
    &-lead{
      font-size: 20px;
      font-weight: bold;
      position: relative;
      margin-bottom: 30px;
      &::before{
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        width: 42px;
        background: #fff;
      }
    }
    &-heading{
      font-size: 30px;
      font-weight: bold;
    }
  }
  &-image{
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 768px){
    &-text{
      display: block;
      height: auto;
      padding: 4%;
      box-sizing: border-box;
      &::after{
        content: '';
        width: 92%;
        height: 47.33vw;
        background: url(/img/img_recycle.jpg)no-repeat;
        background-size: 160%;
        background-position: right center;
        display: block;
        margin: 0 auto;
      }
      &-lead{
        font-size: 4.26vw;
        margin-bottom: 6.13vw;
        &::before{
          bottom: -1.73vw;
        }
      }
      &-heading{
        font-size: 6.4vw;
        margin-bottom: 5.33vw;
      }
    }
    &-image{}
  }
}
.agriculture{
  .side{
    &-text{
      &::after{
        background: url(/img/img_agriculture.jpg)no-repeat;
        background-size: cover;
        background-position: right center;
      }
    }
  }
}
.col{
  &.recycle{
    .side-image{
      background-image: url(/img/img_recycle.jpg);
      background-position: right;
      background-size: 160%;
    }
  }
  &.agriculture{
    .side-image{
      background-image: url(/img/img_agriculture.jpg);
      background-position: center center;
    }
  }
}
.heading-workcontent{
  text-align: center;
  margin-bottom: 18px;
  span{
    display: inline-block;
    margin: 0 auto;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    padding: 1em 1.5em;
    text-align: center;
    border-top: 1px solid $main_color;
    border-bottom: 1px solid $main_color;
  }
  &-text{
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
  }
  @media screen and (max-width: 768px){
    margin-bottom: 13px;
    &-text{
      width: 92%;
      margin: 0 auto;
      text-align: left;
    }
  }
}
.graph{
  display: block;
  margin: 24px auto 110px;
  width: 100%;
  @media screen and (max-width: 768px){
    margin: 20px auto 60px;
  }
}
.daily{
  background-color: #fdeee2;
  background-image:
    linear-gradient(45deg, #f7bd8c 24px, transparent 0),
    linear-gradient(-45deg, #f7bd8c 24px, transparent 0),
    linear-gradient(135deg, #f7bd8c 24px, transparent 0),
    linear-gradient(-135deg, #f7bd8c 24px, transparent 0);
  padding: 75px;
  margin-bottom: 75px;
  .heading-icon{
    margin-bottom: 20px;
  }
  &-text{
    text-align: center;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 768px){
    background-image:
      linear-gradient(45deg, #f7bd8c 14.8px, transparent 0),
      linear-gradient(-45deg, #f7bd8c 14.8px, transparent 0),
      linear-gradient(135deg, #f7bd8c 14.8px, transparent 0),
      linear-gradient(-135deg, #f7bd8c 14.8px, transparent 0);
    margin-bottom: 50px;
    padding: 50px 15px 30px;
    .heading-icon{
      margin: 0 auto 11.5px;
      padding-bottom: 0;
    }
    &-text{
      font-size: 15px;
      text-align: left;
      margin-bottom: 50px;
    }
  }
}
.schedule{
  position: relative;
  &::before{
    content: '';
    width: 4px;
    height: 100%;
    background: #e6e6e6;
    position: absolute;
    left: 10%;
  }
  &-item{
    display: flex;
    background: #fff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    padding: 18px 50px;
    margin-bottom: 25px;
    position: relative;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-time{
    font-size: 37px;
    font-family: 'Teko', sans-serif;
    letter-spacing: .2em;
    position: absolute;
    top: 50%;
    transform: translateY(-38%);
  }
  &-detail{
    font-size: 20px;
    font-weight: bold;
    padding: 9px 9px 9px 50px;
    margin-left: 175px;
    border-left: 1px solid #e6e6e6;
    display: flex;
    &::before{
      content: '';
      width: 4px;
      height: 100%;
      background: $main_color;
      border-radius: 2px;
      margin-right: 25px;
      flex-shrink: 0;
      align-items: stretch;
    }
    .small{
      font-size: 15px;
      margin-left: 1em;
      @media screen and (max-width: 1050px){
        margin-left: 0;
      }
    }
  }
  @media screen and (max-width: 768px){
    &::before{
      left: 50%;
    }
    &-item{
      padding: 18px 15px 18px 20px;
      &:last-child{
      }
    }
    &-time{
      font-size: 30px;
      top: 30px;
    }
    &-detail{
      border: 0;
      margin-left: 70px;
      padding: 0 0 0 11px;
      font-size: 16px;
      &::before{
        width: 2px;
        margin-right: 13px;
      }
      .small{
        font-size: 14px;
        font-weight: 400;
        line-height: 1.78;
      }
    }
  }
}
#facility{
  background: #f2ede2;
}
.facility{
  max-width: 830px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 70px;
  .heading-icon{
    margin-bottom: 62.5px;
  }
  .col{
    justify-content: space-between;
    &-item{
      width: calc((100% - 50px) / 2);
    }
  }
  &-point{
    position: relative;
    letter-spacing: .1em;
    width: 700px;
    max-width: 700px;
    margin: 0 auto 65px;
    z-index: 0;
    &-heading{
      font-size: 21px;
      font-weight: bold;
      line-height: 1.33;
      background: $main_color;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin: 0 auto -35px;
    }
    &-text{
      font-size: 15px;
      line-height: 1.8;
      border: 1px solid $main_color;
      border-radius: 10px;
      background: #fff;
      padding: 45px 20px 20px;
      position: relative;
      z-index: -1;
      span{
        font-size: 25px;
        font-style: normal;
        color: $main_color;
        line-height: 1.52;
      }
    }
  }
  .summary{
    margin-bottom: 70px;
  }
  @media screen and (max-width: 768px){
    padding: 50px 0;
    .heading-icon{
      margin: 0 auto 26px;
    }
    .col{
      &-item{
        width: 100%;
      }
    }
    &-point{
      width: 100%;
      margin-bottom: 25px;
      &-heading{
        width: 72.5px;
        height: 72.5px;
        font-size: 16px;
        margin-bottom: -18px;
      }
      &-text{
        font-size: 14px;
        padding: 25px 20px;
        letter-spacing: 0;
        span{
          font-size: 21.5px;
          text-align: center;
        }
      }
      &:last-of-type{
        margin-bottom: 45px;
      }
    }
    .summary{
      margin-bottom: 50px;
    }
  }
}
.pickup{
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 14px;
  &::before{
    content: '';
    width: 85%;
    height: 1px;
    background-color: #999999;
    position: absolute;
    top: 12px;
    z-index: -1;
  }
  &-item{
    width: 100%;
  }
  &-heading{
    font-weight: bold;
    display: inline-block;
    padding: 0 5px;
    background-color: #fff;
  }
  &-time{}
  @media screen and (max-width: 768px){
    display: block;
    margin-top: 24px;
    margin-bottom: -50px;
    &::before{
      content: none;
    }
    &-item{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 300px;
      margin: 0 auto;
      &:last-child{
        .pickup-heading{
          &::after{
            content: none;
          }
        }
      }
    }
    &-heading{
      width: 95px;
      padding: 0;
      text-align: left;
      position: relative;
      &::before,
      &::after{
        content: '';
        height: 25px;
        width: 0;
        border: 1px solid $main_color;
        border-radius: 2px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after{
        height: 41px;
        border-color: #d8d8d8;
        border-radius: 0;
        top: 112%;
        transform: translateY(0%);
      }
    }
    &-time{
      &-box{
        padding-left: 15px;
        height: 75px;
        .pickup-time{
          text-align: left;
        }
      }
    }
  }
}
.map{
  iframe{
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .map{
    margin-top: 50px;
  }
}

//
// 法人概要
//
.about{
  #corpo{
    background-color: #f2ede2;
  }
  #contact{
    background: #fff;
  }
  @media screen and (max-width: 1050px){
    #corpo{
      margin: 0;
      padding: 50px 5%;
    }
    #contact{
      h3{
        border-color: $main_color;
      }
    }
  }
  @media screen and (max-width: 768px){
    .heading{
      background-image: url(/img/bg_about.jpg);
    }
    #corpo {
      .sp{
        border-bottom: 0;
      }
      .heading-icon{
        margin-top: 0;
      }
    }
  }
}

//
// ご利用案内
//
.guide{
  .heading{
    background-image: url(/img/img_guide.jpg);
    background-position: right -100px top;
    background-size: contain;
    @media screen and (max-width: 1050px){
      background-position: right -60% bottom;
      background-size: 79%;
    }
    @media screen and (max-width: 768px){
      background-position: right top -4vw;
      background-size: cover;
      &-title{
        color: #464646;
      }
    }
  }
}
.step-list{
  width: 75%;
  margin: 115px auto 70px;
  &-item{
    position: relative;
    border-radius: 10px;
    border: 1px solid $main_color;
    background: #fff;
    padding: 27px 50px 24px;
    max-width: 650px;
    width: 87%;
    margin: 0 auto 70px;
    &:last-child{
      .step-list-heading{
        margin-bottom: 0;
      }
    }
  }
  &-number{
    position: absolute;
    top: -75px;
    left: -90px;
    max-width: 100%;
    width: 120px;
  }
  &-heading{
    font-size: 21px;
    font-weight: bold;
    color: $main_color;
    margin-bottom: 10px;
  }
  &-text{
    font-size: 15px;
    line-height: 1.8;
  }
  @media screen and (max-width: 1050px){
    margin-top: 170px;
    &-item{
      margin-bottom: 130px;
    }
    &-number{
      top: 0;
      left: 47%;
      transform: translate(-50%,-90%);
    }
    &-heading{}
    &-text{}
  }
  @media screen and (max-width: 768px){
    width: 92%;
    margin: 127px auto 25px;
    &-item{
      width: auto;
      padding: 32px 20px 14px;
      margin-bottom: 100px;
      &:last-child{
        padding: 35px;
      }
    }
    &-number{
      width: 88px;
    }
    &-heading{
      font-size: 24px;
      text-align: center;
      margin-bottom: 14px;
    }
    &-text{
      font-size: 14px;
    }
  }
}

//
// お問い合わせ
//
#contact{
  width:100%;
  text-align:center;
  margin:0;
  padding:70px 0 70px 0;
  h3{
  	margin:0 0 20px 0;
  	padding: 18px 0 18px 0;
  	font-size:30px;
  	line-height: 1.5em;
  	color:#ee7800;
  	font-weight: bold;
  	text-align:center;
  	border-bottom:1px solid $main_color;
  }
  p{
  	margin:0;
  	padding:0;
  	line-height: 1.8em;
  }
  .btn01{
  	display: block;
  	margin:50px 0 20px 0;
  }
  .btn01 a{
  	background: #f3971c;
  	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
  	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
  	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
  	border-radius: 10px;
  	-webkit-border-radius: 10px;
  	-moz-border-radius: 10px;
  	padding:16px 30px;
  	font-size:21px;
  	line-height: 1.8em;
  	font-weight: bold;
  	color:#fff;
  }
  .btn01 a:hover{
  	background: #ffc578;
  	background: -moz-linear-gradient(top, #ffc578 0%, #fb9d23 100%);
  	background: -webkit-linear-gradient(top, #ffc578 0%,#fb9d23 100%);
  	background: linear-gradient(to bottom, #ffc578 0%,#fb9d23 100%);
  	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0 );
  }
  &.bg-veju{
    h3{
      border-color: #fff;
    }
  }
  @media screen and (max-width: 768px){
    width:90%;
  	text-align:center;
  	padding: 30px 5% 25px;
    h3{
      width: 100%;
      margin: 0 auto 15px;
    	padding: 0 0 15px 0;
    	font-size:24px;
    	color: #ee7800;
    	text-align:center;
    	line-height: 1.5em;
    }

    p{
    	margin: 0;
    	font-size:18px;
    	line-height: 180%;
    }
    .btn01{
    	display: inline-block;
      margin: 20px 0;
    }
    .btn01 a{
    	background: #f3971c;
    	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
    	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
    	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
    	border-radius: 10px;
    	-webkit-border-radius: 10px;
    	-moz-border-radius: 10px;
    	padding:16px 10px;
    	font-size:16px;
    	color:#fff;
    }
    .btn01 a:hover{
    	background: #ffc578;
    	background: -moz-linear-gradient(top, #ffc578 0%, #fb9d23 100%);
    	background: -webkit-linear-gradient(top, #ffc578 0%,#fb9d23 100%);
    	background: linear-gradient(to bottom, #ffc578 0%,#fb9d23 100%);
    	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0 );
    }
  }
}

//
// フッター
//
#footer{
  ul{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  li{
    margin: 0 20px;
    padding: 0;
    border: none;
  }
  a{
    display: inline-block;
    color: #fff;
  }
  @media screen and (max-width: 768px){
    li{
      margin: 0;
    }
    img{
      max-width: 133px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px){
  #footer{
    padding-bottom: 18px;
    .footer-list{
      display: block;
      width: 100%;
      border-top: 1px solid #fff;
      margin: 20px auto 18px;
      &-item{
        display: block;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        border-bottom: 1px solid #fff;
        text-align: left;
        &:last-child{
          border-bottom: 1px solid #fff;
        }
        a{
          display: block;
          text-align: center;
          padding: 10px 8%;
        }
      }
    }
  }
}
